import React, { useEffect, useRef } from "react";
import SEO from "../components/seo";
import { HeroLayout } from "../components/hero-layout";
import Layout from "../components/layout";
import { TextField } from "@rmwc/textfield";
import { Select } from "@rmwc/select";
import "@rmwc/select/styles";
import { rhythm } from "../utils/typography";
import { captureException } from "../components/sentry";
import { navigate } from "gatsby";
import { Button } from "@rmwc/button";
import { LandingApiClient } from "../imports/landing/types/LandingApiClient";

const chatIntegrationOptions = [
  {
    label: "We don't have any",
    value: "none",
  },
  {
    label: "Slack",
    value: "slack",
  },
  {
    label: "Mattermost",
    value: "mattermost",
  },
  {
    label: "Google Chat",
    value: "google-chat",
  },
  {
    label: "Microsoft Teams",
    value: "microsoft-teams",
  },
  {
    label: "Other",
    value: "other",
  },
];

const calendarIntegrationOptions = [
  {
    label: "We don't have any",
    value: "none",
  },
  {
    label: "Google Workspace Calendar",
    value: "google-workspace-calendar",
  },
  {
    label: "Office 365 Outlook",
    value: "office365-outlook",
  },
  {
    label: "Other",
    value: "other",
  },
];

const STEPS = ["NAME", "TEAM", "INTEGRATIONS", "SUCCESS"];
const TITLES = [
  "New workspace details",
  "Workspace integrations",
  "Personal account details",
  "All done! We’ll go ahead and set up your account and reach out to you to schedule an onboarding session.",
];

/**

 export interface OrgSignup extends Entity {
token: string;
email: string;
status: OrgSignupStatus;
firstName?: string;
lastName?: string;
orgName?: string;
ohandle?: string;
chatIntegration?: OrgSignupChatIntegration;
calendarIntegration?: OrgSignupCalendarIntegration;
}
 */

function orgSignupReducer(state, action) {
  return {
    ...state,
    ...action,
  };
}

export const SignUpPage = ({ location }) => {
  const [error, setError] = React.useState();
  const [orgSignup, setOrgSignupState] = React.useReducer(
    orgSignupReducer,
    location.state && location.state.orgSignup
  );
  useEffect(() => {
    if (!orgSignup) {
      captureException(
        new Error("Signup navigated to signup without any info")
      );
      navigate("/error/");
    } else {
      const api = new LandingApiClient(`${process.env.API_URL}/api`);
      api
        .retrieveSignup({ signupToken: orgSignup.token })
        .then((response) => {
          if (response.status === "success" && response.signup) {
            setOrgSignupState(response.signup);
          }
        })
        .catch((e) => {
          // ignore
          captureException(e);
        });
    }
  }, []);

  const [currentStep, setCurrentStep] = React.useState(STEPS[0]);
  const [isOHandleDirty, setIsOHandleDirty] = React.useState(false);

  const onNameChange = (event) => {
    const newName = event.target.value;
    if (isOHandleDirty) {
      setOrgSignupState({
        orgName: newName,
      });
    } else {
      setOrgSignupState({
        orgName: newName,
        ohandle: toOhandle(newName),
      });
    }
  };

  const onOHandleChange = (event) => {
    const newOhandle = event.target.value;
    setIsOHandleDirty(true);
    setOrgSignupState({
      ohandle: newOhandle,
    });
  };

  if (!orgSignup) return null;

  return (
    <Layout withMaxWidth={false}>
      <SEO title={"Create a new Remeet workspace"} />
      <HeroLayout>
        <div className="form-container new-company">
          <h2
            className="mdc-typography--headline4 headline"
            style={{ marginBottom: rhythm(1) }}
          >
            {TITLES[STEPS.findIndex((s) => s === currentStep)]}
          </h2>

          <form
            name="new-company"
            onSubmit={(e) => {
              e.preventDefault();

              const api = new LandingApiClient(`${process.env.API_URL}/api`);

              const save = () => {
                switch (currentStep) {
                  case STEPS[0]:
                    return api.updateSignup({
                      signupToken: orgSignup.token,
                      fields: {
                        orgName: orgSignup.orgName,
                        ohandle: orgSignup.ohandle,
                      },
                    });
                  case STEPS[1]:
                    return api.updateSignup({
                      signupToken: orgSignup.token,
                      fields: {
                        calendarIntegration: orgSignup.calendarIntegration,
                        chatIntegration: orgSignup.chatIntegration,
                      },
                    });
                  case STEPS[2]:
                    return api.submitSignup({
                      signupToken: orgSignup.token,
                      fields: {
                        firstName: orgSignup.firstName,
                        lastName: orgSignup.lastName,
                      },
                    });

                  default:
                    return Promise.reject(new Error("Unknown sign up step"));
                }
              };

              save()
                .then((res) => {
                  if (res.status === "success") {
                    if (currentStep === STEPS[0]) {
                      return setCurrentStep(STEPS[1]);
                    }

                    if (currentStep === STEPS[1]) {
                      return setCurrentStep(STEPS[2]);
                    }

                    if (currentStep === STEPS[2]) {
                      return setCurrentStep(STEPS[3]);
                    }
                  } else if (res.status === "invalid-token") {
                    navigate("/error/", {
                      state: {
                        errorCode: res.code,
                        message: "Token is invalid",
                      },
                    });
                    captureException(
                      new Error("sign up info save - invalid token")
                    );
                  } else {
                    navigate("/error/", {
                      state: {
                        errorCode: res.code,
                      },
                    });
                    captureException(
                      new Error("sign up info save - unexpected error")
                    );
                  }
                })
                .catch((e) => {
                  navigate("/error/");
                  captureException(
                    new Error(
                      "sign up info save - unexpected error: " + e.message
                    )
                  );
                });
            }}
          >
            {currentStep === STEPS[0] && (
              <>
                <p
                  className="mdc-typography--body1"
                  style={{ marginBottom: rhythm(1 / 2) }}
                >
                  What is your team name?
                </p>
                <div
                  className="form-control name-control"
                  style={{ marginBottom: rhythm(1 / 2) }}
                >
                  <TextField
                    outlined
                    name="name"
                    required
                    label="Team name"
                    value={orgSignup.orgName || ""}
                    onChange={onNameChange}
                  />
                </div>
                <div
                  className="form-control url-control"
                  style={{ marginBottom: rhythm(1 / 2) }}
                >
                  <TextField
                    outlined
                    name="ohandle"
                    required
                    label="your-team"
                    pattern="[a-zA-Z0-9-]+"
                    value={orgSignup.ohandle || ""}
                    onChange={onOHandleChange}
                  />
                  <span className="mdc-typography--subtitle1 url-postfix">
                    .remeet.com
                  </span>
                  <div
                    className="hint-message mdc-typography--caption"
                    style={{ marginTop: rhythm(1 / 4) }}
                  >
                    Only letters, dashes and digits allowed in the URL
                  </div>
                </div>
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  {/*<Button*/}
                  {/*  type={"button"}*/}
                  {/*  onClick={() => setCurrentStep(STEPS[0])}*/}
                  {/*  icon={"keyboard_arrow_left"}*/}
                  {/*>*/}
                  {/*  Back*/}
                  {/*</Button>*/}
                  <Button unelevated type={"submit"}>
                    Continue
                  </Button>
                </div>
              </>
            )}

            {currentStep === STEPS[1] && (
              <>
                <p
                  className="mdc-typography--body1"
                  style={{ marginBottom: rhythm(1 / 2) }}
                >
                  What's your team's primary chat software?
                </p>
                <div
                  className="form-control url-control"
                  style={{ marginBottom: rhythm(1) }}
                >
                  <Select
                    name="chatIntegration"
                    label="Chat integration"
                    placeholder={"Select one"}
                    options={chatIntegrationOptions}
                    value={orgSignup.chatIntegration || ""}
                    outlined
                    required
                    onChange={(evt) =>
                      setOrgSignupState({
                        chatIntegration: evt.currentTarget.value,
                      })
                    }
                  />
                </div>
                <p
                  className="mdc-typography--body1"
                  style={{ marginBottom: rhythm(1 / 2) }}
                >
                  What's your team's primary calendar software?
                </p>
                <div
                  className="form-control url-control"
                  style={{ marginBottom: rhythm(1) }}
                >
                  <Select
                    name="calendarIntegration"
                    label="Calendar integration"
                    placeholder={"Select one"}
                    outlined
                    required
                    options={calendarIntegrationOptions}
                    value={orgSignup.calendarIntegration || ""}
                    onChange={(evt) =>
                      setOrgSignupState({
                        calendarIntegration: evt.currentTarget.value,
                      })
                    }
                  />
                </div>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Button
                    type={"button"}
                    onClick={() => setCurrentStep(STEPS[0])}
                    icon={"keyboard_arrow_left"}
                  >
                    Back
                  </Button>
                  <Button unelevated type={"submit"}>
                    Continue
                  </Button>
                </div>
              </>
            )}

            {currentStep === STEPS[2] && (
              <>
                <p
                  className="mdc-typography--body1"
                  style={{ marginBottom: rhythm(1 / 2) }}
                >
                  What is your name?
                </p>
                <div
                  className="form-control name-control"
                  style={{ marginBottom: rhythm(1 / 2) }}
                >
                  <TextField
                    outlined
                    name="firstName"
                    required
                    label="First name"
                    value={orgSignup.firstName || ""}
                    onChange={(e) => {
                      setOrgSignupState({
                        firstName: e.target.value,
                      });
                    }}
                  />
                </div>
                <div
                  className="form-control name-control"
                  style={{ marginBottom: rhythm(1 / 2) }}
                >
                  <TextField
                    outlined
                    name="lastName"
                    required
                    label="Last name"
                    value={orgSignup.lastName || ""}
                    onChange={(e) => {
                      setOrgSignupState({
                        lastName: e.target.value,
                      });
                    }}
                  />
                </div>

                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Button
                    type={"button"}
                    onClick={() => setCurrentStep(STEPS[1])}
                    icon={"keyboard_arrow_left"}
                  >
                    Back
                  </Button>
                  <Button unelevated type={"submit"}>
                    Continue
                  </Button>
                </div>
              </>
            )}
          </form>
        </div>
      </HeroLayout>
    </Layout>
  );
};

export default SignUpPage;

function renderErrorMessage(error) {
  if (error === "409")
    return `Looks like company with this URL is already exists. Try another one?`;
}

function toOhandle(name) {
  if (!name) return "";
  if (typeof name !== "string") return "";
  return name
    .toLowerCase()
    .replace(/[^0-9a-zA-Z]+/g, "-")
    .replace(/-+/g, "-");
}
